<script setup>
  
</script>

<template>
  <div class="box dark b">
      <div class="q">
        <slot name="quote"/>
      </div>
      <div class="p">
        - <slot name="person"/>
      </div>
      <div class="i"><font-awesome-icon style="font-size: 4rem" :icon="['fas', 'quote-left']" /></div>
  </div>
</template>

<style scoped>
.b {
  position: relative;
  border: none !important;
  padding: 3rem !important;
  display: flex;
  flex-direction: column;
}

.p, .q {
  position: relative;
  z-index: 1;
  font-size: 1.2rem; 
}
.q {
  font-style: italic;
  margin-bottom: 1rem;
}

.p {
  margin-top: auto;
  color: white;
  font-style: normal;
}

.i {
  position: absolute;
  left: 1rem;
  top: 1rem;
  color: #262e3c;

}
</style>
