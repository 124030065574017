<script setup>
import { onMounted, defineProps, onUnmounted, ref } from 'vue';

const props = defineProps({
  glow: {
    type: Boolean,
    default: false
  },

  spotlight: {
    type: Boolean,
    default: false
  } 
});

const card = ref(null);
const blob = ref(null);
const fblob = ref(null);

let handle; 

function handle_mousemov(ev) {
  const rec = fblob.value.getBoundingClientRect();
  blob.value.animate([{transform: `translate(${ev.clientX - rec.left - (rec.width / 2)}px,${ev.clientY - rec.top - (rec.height / 2)}px)`}], {fill: "forwards"});
}

onMounted( () => {
  if (props.glow || props.spotlight) {
    window.addEventListener("mousemove", handle_mousemov);
  }
})

onUnmounted( () => {
  if (props.glow || props.spotlight) {
    window.removeEventListener("mousemove", handle_mousemov);
  }
})

</script>

<template>
  <div ref="card" class="wscard">
    <div class="inner">
      <slot></slot>
    </div>
    <div v-if="props.glow" ref="blob" class="blob"></div>
    <div  v-if="props.glow" ref="fblob" class="fakeblob"></div>
  </div>
</template>

<style scoped>

.wscard {
  padding: 2px;
  background-color: #232A35;
  border-radius: 12px;
  overflow: hidden;
  position: relative;
}

.inner {
  position: relative;
  z-index: 1;
  border-radius: 10px;
  background-color: #1B2029;
  height: 100%;
}

.blob {
  filter: blur(40px);
  position: absolute;
  z-index: 0; /* to keep it at below everything else */
  top: 0; /* to align it at top */
  left: 0; /* to align it at left */
  width: 150px;
  height: 150px;
  border-radius: 50%; /* to make it circular */
  background: rgb(255, 255, 255, 0.5);
}

.fakeblob {
  display: hidden;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 200px;
  height: 200px;
  border-radius: 50%;
}

</style>
