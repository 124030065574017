import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL;

class Datastore {
  async list() {
    const { data } =  await axios.get(API_URL + '/dataset/list');
    return data; 
  }

  async quote(product_id, email, contact_person, phone_number, company, country) {
    const { data } =  await axios.post(API_URL + '/datastore/quote', {
      product_id: product_id ,
      email: email,
      contact_person: contact_person,
      phone_number: phone_number,
      company: company,
      country: country
    } );
    return data; 
  }
}

export default new Datastore();
