<script setup>
import {defineProps, computed, watch, defineEmits, onMounted, ref, nextTick} from 'vue'
import Jumbo from '@/components/Docs/Jumbo.vue'
import DocSection from '@/components/Docs/Section.vue'
import CodeBlock from '@/components/Docs/CodeBlock.vue'
import JsonBlock from '@/components/Docs/JsonBlock.vue'

import StringParam from '@/components/Docs/Parameters/StringParam.vue'
import RequestRunner from '@/components/Docs/RequestRunner.vue'

import BasicAttribute from '@/components/Docs/Attributes/BasicAttribute.vue'

let get_query = computed(() => {
  return "/dataset/list" 
}) 

</script>

<template>
  <div>
    <doc-section title="List Datasets">
      <template v-slot:left>
        <div class="mb-5">
This endpoint allows users to retrieve a list of all datasets offered by Webscout
        </div>
      
        <div class="mb-5">
          <h4 class="mb-4">Request parameters</h4>
          <RequestRunner class="mt-5" :query="get_query" name="Domain information" method="GET"></RequestRunner>
        </div>

        <div>
          <h4 class="mb-4">Response attributes</h4>

          <BasicAttribute name="status" type="string" description="The status of the request's response" />
          <hr>

          <BasicAttribute name="data" type="object array">

            <BasicAttribute name="id" type="string" description="The id of the dataset, used when downloading datasets programmatically" />

            <BasicAttribute name="name" type="string" description="The display name of the dataset" />

            <BasicAttribute name="category" type="string" description="The group to which the dataset belong" />

            <BasicAttribute name="last_update" type="string" description="When webscout last changed, added or removed entries from the dataset" />

            <BasicAttribute name="num_entries" type="integer" description="The number of entries currently in the dataset" />

            <BasicAttribute name="csv_size" type="integer" description="CSV size after decompression" />
            <BasicAttribute name="mmdb_size" type="integer" description="MMDB size after decompression" />
            <BasicAttribute name="txt_size" type="integer" description="TXT size after decompression" />
            <BasicAttribute name="jsonl_size" type="integer" description="JSONL size after decompression" />

            <BasicAttribute name="csv_sha256" type="string" description="CSV SHA256 checksum after decompression" />
            <BasicAttribute name="mmdb_sha256" type="string" description="MMDB SHA256 checksum after decompression" />
            <BasicAttribute name="txt_sha256" type="string" description="TXT SHA256 checksum after decompression" />
            <BasicAttribute name="jsonl_sha256" type="string" description="JSONL SHA256 checksum after decompression" />

            <BasicAttribute name="sample_link" type="string" description="HTTPS link to the sample of the given dataset" />

            <BasicAttribute name="has_active_license" type="boolean" description="Boolean indicating whether the current user has an active license to the dataset" />
            <BasicAttribute name="license_end" type="string" description="Timestamp indicating when the license (if any) ends" />

          </BasicAttribute>

        </div>

      </template>

      <template v-slot:right>
        <code-block title="/dataset/list" method="GET" dark/>
      
        <json-block title="Response">
{
"status": "success",
"data": [
{
"id": 
"1d6cde87-1d7c-45fd-8499-ec5d5966a839",
"name": 
"City + ASN",
"category": 
"Ip",
"last_update": 
"2024-12-30T14:39:44.212472Z",
"num_entries": 
2274024,
"csv_size": 
174366720,
"mmdb_size": 
30846846,
"jsonl_size": 
418527005,
"txt_size": 
null,
"csv_sha256": 
"67f707e932159e56a42c7558b0b905a6077901e05ce12defcb46ba265d4c3c81",
"mmdb_sha256": 
"3cd2a186efc24d553c8f2a99fd762284473fdfdd8155e2e2943ac466dd74bdb1",
"jsonl_sha256": 
"e7acb8e6bca3a6d880e2953d713ccee621a05597d2c2e853e5573276e9e033ad",
"txt_sha256": 
null,
"sample_link": 
"https://files.webscout.io/samples/city-asn-sample.csv",
"has_active_license": 
false,
"license_end": 
"0001-01-01T00:00:00Z"
}]
}
        </json-block>
      </template>
    </doc-section>
  </div>
</template>

<style scoped>
</style>
