<script setup>
import { ref } from 'vue'
import { useMainStore } from '@/stores/main'
const mainStore = useMainStore()
import UserApi from '@/api/User.js'

import Card from '@/components/Card.vue'

let sender = ref("")
let name = ref("")
let topic = ref("")
let message = ref("")

let is_submitting = ref(false)
let failed = ref(false)
let is_sent = ref(false)

async function send() {
  is_submitting.value = true

  let res = await UserApi.contact( sender.value, name.value, topic.value, message.value )

  if (res.status == "failed") {
    failed.value = true
  }

  is_submitting.value = false
  is_sent.value = true
}
</script>

<template>
<div class="container">
  <div class="row">
    <div class="col-md-6">
        <h1 class="title">Contact Us</h1>
        <p class="mb-5">
We’re here to help you strengthen your defenses and make the most of our solutions. Whether you have questions about our products, need support, or want to learn more about how we can assist your organization, our team is ready to assist you.
        </p>


        <h1 class="sub-title">Support inqueries</h1>
        <p class="mb-5">
Have a technical issue or need assistance with integration? Reach out to our support team at support@webscout.io for fast and reliable help.
        </p>

        <h1 class="sub-title">Request a demo</h1>
        <p class="mb-5">
Curious about our datasets, blocklists, or monitoring tools? Schedule a personalized demo to see how we can meet your cybersecurity needs. Contact us at hello@webscout.io or use the form to the right.

We look forward to connecting with you!
        </p>


        <h1 class="sub-title">About us</h1>
        <p class="enlarged">Based in the heart of Copenhagen, Denmark, Webscout is an internet intelligence company specialized in tagging and enriching IP addresses and domain names with vital context, behavioral insights, and important metadata. Our mission is to supercharge threat detection and prevention by providing organizations with comprehensive and actionable internet intelligence. </p>

    </div>

    <div class="col-md-6">

      <div v-if="failed">
        <Card>
          <div style="padding: 2rem;" >
            <div class="text-center mb-4">
               <font-awesome-icon style="font-size: 5rem; color: #ca5050;" :icon="['fas', 'circle-exclamation']"/>
            </div>

            <h3 class="title mb-4 text-center">Failed</h3>
            <div class="text-center d-flex column centered">
              <p style="max-width: 700px;">Sorry! Something went wrong. Please try again later, or send an email directly to <a href="mailto:help@webscout.io"><strong class="white">hello@webscout.io</strong></a> with your question</p>
            </div>
          </div>
        </Card>
      </div>
      <Card v-else-if="!is_sent">
        <form class="form search_form form-floating" id="f" @submit.prevent="send" style="flex-grow: 1; padding: 1rem;">
            <!--<input class="search_input form-control" id="floating" ref="i" v-model="search_input" placeholder="Query (CTRL+K)">-->
          <div class="form-floating mb-2">
              <input type="text" v-model="sender" class="form-control" id="floatingInput" placeholder="sender" >
              <label for="floatingInput">Email</label>
          </div>

          <div class="form-floating mb-2">
              <input type="text" v-model="name" class="form-control" id="floatingInput" placeholder="sender" >
              <label for="floatingInput">Name</label>
          </div>

          <div class="form-floating mb-2">
              <input type="text" v-model="topic" class="form-control" id="floatingInput" placeholder="subject" >
              <label for="floatingInput">Topic</label>
          </div>

          <div class="form-floating mb-2">
            <textarea class="form-control" v-model="message" id="floatingPassword" placeholder="Password" style="height: 300px;" resi></textarea>
            <label for="floatingInput">message</label>
          </div>
          
          <div class="d-flex centered">
            <button class="button" type="submit" form="f" >Send</button>
            <!--<button class="button" v-else :class="{'disabled': is_submitting}" >Sending...</button>-->
          </div>
        </form>
      </Card>
      <Transition v-else>
      <Card>
        <div  style="padding: 2rem;">
          <div class="text-center mb-4">
            <img src="~@/assets/icons/check-circle-filled.svg" width="100" height="100" alt="">
          </div>

          <h3 class="title mb-4 text-center">Sent!</h3>
          <div class="text-center d-flex column centered">
            <p style="max-width: 700px;">We are happy to hear from you - we will get back to you within a day or two</p>
            <p>Thank you!</p>
          </div>
        </div>
      </Card>
      </Transition>

    </div>
  </div>

</div>

</template>

<style scoped>
.title {
  font-size: 2rem !important;
}


.sub-title {
  font-weight: bold;
  font-size: 1.5rem;
  display: inline-block;
  background: linear-gradient(90deg, #ffffffb8, white, #ffffffb8);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.solution {
  height: 350px;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  background-image: linear-gradient(150deg, #1B2029 -30%, #181D26 110%);
  border-radius: 10px;

  h5 {
    margin: 0;
  }
}

.box-header {
  font-size: 1.3rem;
  color: white;
}

.integration {
  display: flex;
  align-items: center;

  margin-bottom: 1rem;
}

.integration .text {
  color: white;
  font-size: 1.1rem;
}

.integration .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #bfc4ce;
  padding: .8rem;
  border-radius: 7px;
  background-color: #2f3642;
  margin-right: 1rem;
}

.box {
  height: 250px;
}

.box img {
  opacity: .4;
}

.button {
  display: flex;
  padding: .4rem 1.1rem;
  border-radius: 10px;
  background-color: #5094ed;
  color: white;
  align-items: center;
  border: none;
}

.button:hover {
  background-color: #4c82c8;
  cursor: pointer;
}

.button.black {
  background-color: #232A35;
}

.button.black:hover {
  background-color: #2F3744;
}

.enlarged {
  font-weight: 400;
  color: #7f8796;
}

</style>

