<script setup>
import { ref, defineProps, nextTick, onMounted, updated, onUpdated, computed } from 'vue'
import Section from '@/components/Docs/Section.vue'
import { ScrollSpy } from 'bootstrap'

import Introduction from '@/views/Authenticated/Docs/Api/Sections/Introduction.vue'
import Access from '@/views/Authenticated/Docs/Api/Sections/Access.vue'
import Authentication from '@/views/Authenticated/Docs/Api/Sections/Authentication.vue'
import ResponseTypes from '@/views/Authenticated/Docs/Api/Sections/ResponseTypes.vue'
import FairUse from '@/views/Authenticated/Docs/Api/Sections/FairUse.vue'

import IpInfo from '@/views/Authenticated/Docs/Api/Sections/IpInfo.vue'
import DomainInfo from '@/views/Authenticated/Docs/Api/Sections/DomainInfo.vue'
import Subdomains from '@/views/Authenticated/Docs/Api/Sections/Subdomains.vue'
import SimilarDomains from '@/views/Authenticated/Docs/Api/Sections/SimilarDomains.vue'
import Emails from '@/views/Authenticated/Docs/Api/Sections/Emails.vue'
import ReverseDns from '@/views/Authenticated/Docs/Api/Sections/IpReverseDns.vue'
import Hash from '@/views/Authenticated/Docs/Api/Sections/Hash.vue'

import ListDatasets from '@/views/Authenticated/Docs/Api/Sections/ListDataset.vue'
import DownloadDataset from '@/views/Authenticated/Docs/Api/Sections/DownloadDataset.vue'

const dataSpyEl = ref(null)

onMounted( async () => {
  let el = ScrollSpy.getOrCreateInstance(dataSpyEl.value)
})

</script>

<template>
  <div style="min-height: 100%;" class="d-flex column">
    <div class="container-fluid" style="flex:1; height: calc(100% - 60px)">
      <div class="row" style="height: 100%;">
        <!-- Content -->
        <div class="col-md-10 pb-4 wp" style="height: 100%; overflow: scroll;" data-bs-spy="scroll" ref="dataSpyEl" data-bs-target="#navbar-docs" data-bs-smooth-scroll="true"  tabindex="0">
            <!-- GENERAL INFORMATION -->
            <Introduction id="item-1"/>
            <Access id="item-2"/>
            <ResponseTypes id="item-3"/>
            <Authentication id="item-4"/>
            <FairUse id="item-5"/>
            
            <!-- API SECTIONS -->
            <IpInfo id="item-6"/>
            <DomainInfo id="item-7"/>
            <Subdomains id="item-8"/>
            <SimilarDomains id="item-9"/>
            <Emails id="item-10"/>
            <ReverseDns id="item-11"/>
            <Hash id="item-12"/>
            <ListDatasets id="item-13"/>
            <DownloadDataset id="item-14"/>
            <!--<IpInfo/>-->
        </div>

        <!-- Navigation -->
        <div class="col-md-2 sidebar pt-3">
            <strong class="mb-2 d-flex"><small>General information</small></strong>
            <hr>

            <nav id="navbar-docs" class="">
              <nav class="nav flex-column">
                <a class="nav-link" href="#item-1">Introduction</a>
                <!--
                <nav class="nav nav-pills flex-column">
                  <a class="nav-link ms-3 my-1" href="#item-1-1">Item 1-1</a>
                  <a class="nav-link ms-3 my-1" href="#item-1-2">Item 1-2</a>
                </nav>
                -->
                <a class="nav-link" href="#item-2">Response types</a>
                <a class="nav-link" href="#item-4">Authentication</a>
                <a class="nav-link" href="#item-5">Fair use</a>

                <strong class="mb-2 d-flex mt-4"><small>Reference</small></strong>
                <hr>
                <a class="nav-link" href="#item-6">IP lookup</a>
                <a class="nav-link" href="#item-7">Domain lookup</a>
                <a class="nav-link" href="#item-8">Subdomains</a>
                <a class="nav-link" href="#item-9">Similar domains</a>
                <a class="nav-link" href="#item-10">Emails</a>
                <a class="nav-link" href="#item-11">Reverse DNS</a>
                <a class="nav-link" href="#item-12">Hash lookup</a>
                <a class="nav-link" href="#item-13">List datasets</a>
                <a class="nav-link" href="#item-14">Download datasets</a>
              </nav>
            </nav>
          

        </div>

      </div>

    </div>

  </div>
</template>



<style scoped>
.sidebar {
  border-left: 1px solid #1e252f;
}

.list-group-item {
  background-color: #191f27;
  border-color: #1e252f;
  color: #6d7785;
}

.list-group-item:hover {
  background-color: #1c222b
}

.router-link-exact-active {
  font-weight: 500 !important;
  color: white !important;
}

.wp > * {
  margin-top: 2.5rem;
}

.wp > *:not(:first-child) {
  border-top: 2px solid #1e252f;
  padding-top: 2.5rem;
}

#navbar-docs a {
  color: #6d7785;
  border-left: 5px solid transparent;
  transition: .5s ease;
}

#navbar-docs a.active {
  color: white;
  border-color: #bf75fe;
}
</style>
