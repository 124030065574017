<script setup>
import { onMounted, ref, computed } from 'vue'
import Plans from '@/data/plans.js'

import ContactModal from '@/components/modals/Contact.vue'

import { useMainStore } from '@/stores/main'
const mainStore = useMainStore()

import { useUserStore } from '@/stores/user'
let userStore = useUserStore()


const prof_message = `Hi Webscout,

I would like to know more about the professional plan.

Please get back to me.

Kind regards,
`+userStore.name


const enterprise_message = `Hi Webscout,

I would like to know more about the enterprise plan.

Please get back to me.

Kind regards,
`+userStore.name

//mainStore.show_modal(qf, { fileName: file.name, domains: res.domains, ips: res.ips})
function contact(type) {
  if(type == 2) {
    mainStore.show_modal(ContactModal, { sender: userStore.email, name: userStore.name, product: "Plan: Professional", message: prof_message }) 
  } else {
    mainStore.show_modal(ContactModal, { sender: userStore.email, name: userStore.name, product: "Plan: Enterprise", message: enterprise_message }) 
  }
}

</script>
<template>
    <div id="wrapper-pricing" class="p-3">
      <div class="box mb-3 med-padded d-flex vertically-centered">
        <font-awesome-icon style="color: #5094ed; font-size: 1.2rem;" class="me-4" :icon="['fas', 'chart-simple']" />
        <h5 class="mb-0">Plans</h5>
      </div>
      <div class="d-flex">
        <!-- <h1 class="display-5 title centered mb-5">Transparent pricing</h1> -->
        <div class="box none padded me-3 d-flex column" v-for="plan in Plans" :key="plan" style="color: rgb(157, 165, 176); width: 25rem;">

          <div class="title mb-3">
            {{ plan.title }}
          </div>

          <div class="mb-3">
            {{ plan.caption }}
          </div>

          <div class="mb-3">
            <span class="price">{{ plan.price }}</span>
            <span>{{ plan.frequency }}</span>
            
          </div>

          <div class="mb-5">
            <div v-for="feature in plan.features" class="mb-3 d-flex"  :key="feature">
              <font-awesome-icon style="color: #5094ed; font-size: 1rem; margin-top: .25rem;" class="me-3" :icon="['fas', 'circle-check']" />
              <div>
              {{feature}}
              </div>
            </div>
          </div>
          
          <div class="text-center mt-auto">
            <div class="mb-2">
              <button v-if="plan.contact_buy" @click="contact(plan.account_type)">Contact Us</button>
              <button v-else-if="plan.account_type ==  userStore.accountType" class="disabled">Current Plan</button>
              <button v-else-if="plan.account_type <  userStore.accountType">Downgrade</button>
              <button v-else>Upgrade</button>
            </div>

            <div>
              {{ plan.restriction }}
            </div>
          </div>


          <div>
          </div>
        </div>



      </div>
    </div>
</template>
<style scoped>
.title {
  font-size: 1.3rem;
  color: white;
  font-weight: normal;
}

.price {
  font-size: 2rem;
  color: white;
}

.box.none {
  background: transparent;
  border: 2px solid #1e252f;
}

button {
  background-color: #1E252F;
  border-radius: 5px;
  border:none;
  margin-right: 5px;
  padding: 0 10px;
  font-weight: bold;
  color: white;
  padding: .8rem 0px;
  width: 100%;
}

button.dark {
  background-color: #131820
}

button:hover {
  background-color: #2B3440;
}

button.disabled {
  background-color:  #171C24
}

button.disabled:hover {
  background-color:  #171C24;
  cursor: default;
}
</style>
