<script setup>
import {defineProps} from 'vue'
const props = defineProps({
  "data": { Type: Array, Default: [] }, 
  "maxlen": { Type: Number, Default: 3 },
  "nostyle": { Type: Boolean, Default: false }
})

let shown_tags = [];
let hidden_tags = [];

if (props.maxlen != undefined && props.maxlen != null && props.data != null && props.data.length > 0) {
  for (let i=0; i < props.data.length; i++) {
    if (i < props.maxlen) {
      shown_tags.push(props.data[i]) 
    } else {
      hidden_tags.push(props.data[i])
    }
  }
} 

</script>
<template>
  <div>
    <div v-if="props.data != null && props.data.length > 0"  >

      <div v-if="props.maxlen == undefined || props.maxlen == null || props.data.length <= props.maxlen" class="d-flex vertically-centered">
        <div v-for="tag in props.data">
          <div :class="{'tag': !nostyle, 'nsm': nostyle}" v-if="tag != ''">
            <span >{{tag}}</span>
          </div>
        </div>
      </div>
      <div v-else style="position: relative;" class="d-flex vertically-centered" >
        <div v-for="tag in shown_tags">
          <div :class="{'tag': !nostyle, 'nsm': nostyle}" v-if="tag != ''">
            <span >{{tag}}</span>
          </div>
        </div>
        <span class="tag dropdown dropdown-toggle" data-bs-toggle="dropdown" ><span>+ {{hidden_tags.length}}</span></span>
        <ul class="dropdown-menu scrollable">
          <li v-for="st in hidden_tags" >{{st}}</li>
        </ul>
      </div>

    </div>
  </div>
</template>

<style scoped>
.dropdown-menu li {
  color:white  !important;;
  padding: 0 .5rem;
} 
.dropdown-menu {
  background-color: #151a22 !important;
}

.tag {
  position: relative;
  border: 1px solid #616B79;
  border-radius: 20px;
  font-size: .9rem;
  padding: 0 .5rem;
  margin-right: .1rem;
}

.nsm {
  margin-right: .5rem;
}


.scrollable {
  max-height: 100px;
  overflow-y: auto;
}
</style>
