import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
// import store from './store'
//import axios from 'axios'
import { createPinia } from 'pinia'

// import "bootstrap/dist/css/bootstrap.min.css"
// import "bootstrap"

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import specific icons */
import { faXmark, faTableColumns, faMessage, faCheck, faForward, faFlag, faBuilding, faFire, faQuoteLeft, faWifi, faAddressCard, faSitemap, faViruses, faLocationDot, faCircleQuestion, faHand, faCircleInfo, faVirus, faChevronRight, faTableList, faDownload, faChartSimple, faPlay, faUserSecret, faBook, faSquareRss, faCopy, faRss, faUserClock, faPlus, faKey, faArchive, faTrash, faClockRotateLeft, faCode, faUserAstronaut, faWater, faFish, faBullhorn, faCompactDisc, faCompass, faUnlock, faRotateRight, faCircleNotch, faCircleExclamation, faPlugCircleExclamation, faArrowRight, faArrowDown, faLink, faDiagramProject, faCaretRight, faChartLine, faShieldHalved, faBell, faGear, faEyeSlash, faCircleCheck, faEllipsis,  faGlobe, faCookie, faUsers, faClock, faLock, faEnvelope, faEye, faDatabase, faUniversalAccess, faAt, faShuffle, faPenToSquare, faServer, faMaximize, faPlug, faMedal, faCaretDown, faCaretUp, faMagnifyingGlass, faLayerGroup, faHurricane, faStar } from '@fortawesome/free-solid-svg-icons'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import { faLinux, faWindows, faUbuntu, faDigitalOcean,faFacebook, faTwitter, faInstagram, faAws, faCloudflare, faGoogle} from '@fortawesome/free-brands-svg-icons'

/* add icons to the library */
library.add(faLinux, faHand, faMessage, faCheck, faForward, faUniversalAccess, faShuffle, faPlug, faPenToSquare, faFlag, faMaximize, faMedal, faBuilding, faFire, faPlay, faWifi, faSitemap, faAddressCard, faLocationDot, faQuoteLeft, faViruses, faCircleQuestion, faCircleInfo, faVirus, faChevronRight, faTableList, faDownload, faCircleNotch, faChartSimple, faUserSecret, faRss, faBook, faSquareRss, faCopy, faKey, faUserClock, faPlus, faLink, faArchive, faTrash, faCode, faClockRotateLeft, faUserAstronaut, faWater, faFish, faBullhorn, faCompactDisc, faCompass, faUnlock, faCircleExclamation, faRotateRight, faPlugCircleExclamation, faFacebook, faArrowDown, faTwitter, faInstagram, faArrowRight, faDigitalOcean, faDiagramProject, faCaretRight, faChartLine, faShieldHalved, faBell, faEyeSlash, faCircleCheck, faEllipsis, faAws, faCookie, faTableColumns, faUsers, faLock, faCloudflare, faGoogle, faClock, faWindows, faUbuntu, faEye, faAt, faXmark, faGear, faServer, faGlobe, faLayerGroup, faEnvelope, faDatabase, faCaretDown, faCaretUp, faMagnifyingGlass, faHurricane, faStar)

const app = createApp(App)
    .use(createPinia())
    .use(router)
    .component('font-awesome-icon', FontAwesomeIcon)
/* add font awesome icon component */

// this ensures cookies can be sent with request
//axios.defaults.withCredentials = true;

// this will make the axios accessible as this.$http
// app.config.globalProperties.$http = axios;
// mount app
app.mount('#app')
