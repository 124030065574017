<script setup>
import { onMounted, defineProps, ref, computed } from 'vue'
import { useUserStore } from '@/stores/user'
import moment from 'moment'
import { useMainStore } from '@/stores/main'
let mainStore = useMainStore()

const props = defineProps({
  dataset: Object
});

import ContactModal from '@/components/modals/Contact.vue'

const userStore = useUserStore()
const datasets = ref([])
const API_URL = process.env.VUE_APP_API_URL;
const format = ref("")

const full_checksum = computed( () => {
  if (format.value == "jsonl") {
    return props.dataset.jsonl_sha256
  } else if (format.value == "csv") {
    return props.dataset.csv_sha256
  } else if (format.value == "mmdb") {
    return props.dataset.mmdb_sha256
  } else if (format.value == "txt") {
    return props.dataset.txt_sha256
  } else {
    return "..."
  }
})

const current_checksum = computed( () => {
  if (full_checksum.value == "...") {
    return "..."
  } else {
    return full_checksum.value.slice(0,4) + "..." + full_checksum.value.slice(-4)
  }
})

const current_size = computed( () => {
  if (format.value == "jsonl") {
    return humanFileSize(props.dataset.jsonl_size, true)
  } else if (format.value == "csv") {
    return humanFileSize(props.dataset.csv_size, true)
  } else if (format.value == "mmdb") {
    return humanFileSize(props.dataset.mmdb_size, true)
  } else if (format.value == "txt") {
    return humanFileSize(props.dataset.txt_size, true)
  } else {
    return format_size_range(props.dataset)
  }
})

const formats = computed( () => {
  let formats = []
  if (props.dataset.csv_sha256 != "" && props.dataset.csv_sha256 != null) { formats.push("csv") }
  if (props.dataset.jsonl_sha256 != ""  && props.dataset.jsonl_sha256 != null) { formats.push("jsonl") }
  if (props.dataset.mmdb_sha256 != "" && props.dataset.mmdb_sha256 != null) { formats.push("mmdb") }
  if (props.dataset.txt_sha256 != "" && props.dataset.txt_sha256 != null) { formats.push("txt") }

  return formats
})

function contact() {
  mainStore.show_modal(ContactModal, { sender: userStore.email, name: userStore.name }) 
}

function formats_str(dataset) {
  return formats.value.join(", ")
}

function format_size_range(dataset) {
  let sizes = []
  if (dataset.csv_sha256 != "" && dataset.csv_sha256 != null) { sizes.push(dataset.csv_size) }
  if (dataset.jsonl_sha256 != "" && dataset.jsonl_sha256 != null) { sizes.push(dataset.jsonl_size) }
  if (dataset.mmdb_sha256 != "" && dataset.mmdb_sha256 != null) { sizes.push(dataset.mmdb_size) }
  if (dataset.txt_sha256 != "" && dataset.txt_sha256 != null) { sizes.push(dataset.txt_size) }

  if (sizes.length == 0) {
    return "0"
  } else if (sizes.length == 1) {
    return humanFileSize(sizes[0], true)
  }

  let max = Math.max(...sizes)
  let min = Math.min(...sizes)
  return humanFileSize(min, true) + " - " + humanFileSize(max, true)
}

if (formats.value.length == 1) {
  format.value = formats.value[0];
}

/**
 * Format bytes as human-readable text.
 * 
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use 
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 * 
 * @return Formatted string.
 */
function humanFileSize(bytes, si=false, dp=1) {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = si 
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] 
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10**dp;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


  return bytes.toFixed(dp) + ' ' + units[u];
}

function valid_download(e) {

  if (format.value != "") {
    return 
  }
  e.preventDefault()
}

function getIcon(dataset_name) {
  if (dataset_name == "Country") return "flag"
  else if (dataset_name == "City + ASN") return "location-dot"
  else if (dataset_name == "Organization & Risk") return "building"
  else if (dataset_name == "Anonymization") return "user-secret"
  else if (dataset_name == "Threats") return "wifi"
  else if (dataset_name == "Forward DNS") return "arrow-right"
  else if (dataset_name == "New Domains") return "fire"

  else return "shield-halved"
}
</script>

<template>
  <div>

    <!-- <h1 class="display-5 title centered mb-5">Transparent pricing</h1> -->
    <div class="d-flex column" style="color: rgb(157, 165, 176); width: 25rem; padding: 2rem; height: 100%;">
      <div class="integration mb-3">
        <div class="icon">
          <font-awesome-icon style="font-size: 1.3rem;" :icon="['fas', getIcon(dataset.name)]" />
        </div>
        <h5 class="mb-0">{{ dataset.name }}</h5>
      </div>  

      <div class="mb-4" v-if="dataset.has_active_license">
        <span class="badge text-bg-secondary">access expires {{ moment(dataset.license_end).fromNow() }}</span>
      </div>
      <div class="mb-5 mt-auto">
        <div class="mb-3 d-flex">
          <div class="field-name">
            Checksum 
          </div>
          <div class="ms-auto" style="position: relative" data-bs-toggle="tooltip" data-bs-placement="top" :title="full_checksum">{{ current_checksum }}</div>
        </div>
        <div class="mb-3 d-flex">
          <div class="field-name">
            Updated
          </div>
          <div class="ms-auto">{{ moment(dataset.last_update).fromNow() }}</div>
        </div>
        <div class="mb-3 d-flex">
          <div class="field-name">
            File size 
          </div>
          <div class="ms-auto">{{ current_size }}</div>
        </div>
        <div class="mb-3 d-flex">
          <div class="field-name">
            Number of Entries 
          </div>
          <div class="ms-auto">{{ dataset.num_entries }}</div>
        </div>
        <div class="d-flex">
          <div class="field-name">
            Formats 
          </div>
          <div class="ms-auto">{{ formats_str(dataset) }}</div>
        </div>
      </div>
      <div class="text-center">
        <div v-if="dataset.has_active_license" class="d-flex">
          <select v-model="format" class="form-select me-2" aria-label="Default select example" :disabled="formats.length == 1">
            <option disabled value="">Select format</option>
            <option v-for="f in formats" :value="f">{{f}}</option>
          </select>
          <a class="button black" :href="API_URL + '/dataset/download/' + dataset.id + '/' + format + '?token='+ userStore.token" download target="_blank" @click="valid_download">
            <font-awesome-icon :icon="['fas', 'download']" />
          </a>
        </div>
        <div v-else class="d-flex">
              <span class="button me-2" @click="contact()" style="flex: 1;">
                <strong>Get a quote</strong>
              </span>
              <a class="button black mt-auto" :href="dataset.sample_link" download target="_blank">
                <font-awesome-icon style="font-size: 1rem; color:#bfc4ce" class="me-2" :icon="['fas', 'download']" />
                <div>Sample</div>
              </a>
        </div>
      </div>
    </div>
    
  </div>
</template>

<style scoped>
.title {
  font-size: 1.5rem;
  color: white;
  font-weight: normal;
}

.price {
  font-size: 2rem;
  color: white;
}

.box.none {
  background: transparent;
  border: 2px solid #1e252f;
}

button {
  background-color: #1E252F;
  border-radius: 10px;
  border:none;
  font-weight: bold;
  color: white;
  padding: .8rem 1.5rem;
}

.form-select {
  border-radius: 10px;
  cursor: pointer;
  padding-left: 1rem;
}

button.dark {
  background-color: #131820
}

button:hover {
  background-color: #2B3440;
}

button.disabled {
  background-color:  #171C24
}

button.disabled:hover {
  background-color:  #171C24;
  cursor: default;
}

.field-name {
  color: white;
}

.text-bold {
  font-weight: bold;
}

.integration {
  display: flex;
  align-items: center;

  margin-bottom: 1rem;
}

.integration .text {
  color: white;
  font-size: 1.1rem;
}

.integration .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #bfc4ce;
  padding: .8rem;
  border-radius: 7px;
  background-color: #2f3642;
  margin-right: 1rem;
}

.button {
  display: flex;
  padding: .8rem 1.5rem;
  border-radius: 10px;
  background-color: #5094ed;
  color: white;
  align-items: center;
  font-size: 18px; 
  line-height: 18px;
}

.button:hover {
  background-color: #4c82c8;
  cursor: pointer;
}

.button.black {
  background-color: #232A35;
}

.button.black:hover {
  background-color: #2F3744;
}

.form-select:disabled {
  background-color: #1F2631;
  color: white;
}
</style>
