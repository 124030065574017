<script setup>
import {defineProps, computed, watch, defineEmits, onMounted, ref, nextTick} from 'vue'
import Jumbo from '@/components/Docs/Jumbo.vue'
import DocSection from '@/components/Docs/Section.vue'
import CodeBlock from '@/components/Docs/CodeBlock.vue'
import JsonBlock from '@/components/Docs/JsonBlock.vue'

import StringParam from '@/components/Docs/Parameters/StringParam.vue'
import RequestRunner from '@/components/Docs/RequestRunner.vue'

import BasicAttribute from '@/components/Docs/Attributes/BasicAttribute.vue'

let dataset_id = ref("")
let dataset_format = ref("")


let get_query = computed(() => {
  return "/dataset/download" 
}) 

</script>

<template>
  <div>
    <doc-section title="Download Dataset">
      <template v-slot:left>
        <div class="mb-5">
This endpoint allows users to download a given dataset. A list of datasets can be obtained by querying /dataset/list
        </div>
      
        <div class="mb-5">
          <h4 class="mb-4">Request parameters</h4>

          <StringParam class="mb-3" name="Dataset ID" :required="true" description="The ID of the dataset you wish to download" type="string" :value="dataset_id" @value="(v) => dataset_id = v" />

          <StringParam class="mb-3" name="Format" :required="true" description="Available formats vary depending on dataset but must be one of: txt, jsonl, mmdb or csv" type="string"/>
          <!-- <RequestRunner class="mt-5" :query="get_query" name="Domain information" method="GET"></RequestRunner> -->
        </div>

        <div>
          <h4 class="mb-4">Response attributes</h4>

          <BasicAttribute name="file" type="octet-stream" description="The dataset in the requested format" />

        </div>

      </template>

      <template v-slot:right>
        <code-block title="/dataset/download/:dataset_id/:format" method="GET" dark/>
      
        <code-block title="Response" text dark>
          file object
        </code-block>
      </template>
    </doc-section>
  </div>
</template>

<style scoped>
</style>
